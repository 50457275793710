import React, { useState, useCallback, useMemo, useRef, useEffect, ReactElement, ReactComponentElement } from 'react';
import { useParams, useNavigate, useLocation, useMatch } from 'react-router-dom';
// import queryString from 'qs';

/**
 * Manages form state
 * @param {object} initialState form values to start with
 * @returns {Array} React stateful value and function to update it
 */
export function useFormFields(initialState) {
    const [fields, setValues] = useState(initialState);

    return [
        fields,
        function (event) {
            setValues({
                ...fields,
                [event.target.id]: event.target.value
            });
        }
    ];
}

/**
 * Toggle boolean option in a stateful way
 * @param {boolean} initialValue value to start with
 * @returns {Array} React stateful value and function to update it
 */
export function useToggle(initialValue = false) { // reference: https://joshwcomeau.com/snippets/react-hooks/use-toggle
    const [value, setValue] = useState(initialValue),
        toggle = useCallback(() => {
            setValue(v => !v);
        }, []);

    return [value, toggle];
}

/**
 * Organizes routing values & functions into one object
 * @param {string} route path to match
 * @returns {object} object containin functions used to navigate
 */
export function useRouter(route) { // reference: https://usehooks.com/useRouter/
    const params = useParams();
    const location = useLocation();
    const history = useNavigate();
    const match = useMatch(route || location.pathname);
    const matchParams = match && match.params ? match.params : {};
    const querystring = new URLSearchParams(location.search);
    const origin = window && window.location && window.location.origin;

    // Return our custom router object
    // Memoize so that a new object is only returned if something changes
    return useMemo(() => {
        return {
            // For convenience add push(), replace(), pathname at top level
            // push: history.push,
            // replace: history.replace,
            pathname: location.pathname,
            // Merge params and parsed query string into single 'query' object
            // so that they can be used interchangeably.
            // Example: /:topic?sort=popular -> { topic: 'react', sort: 'popular' }
            query: {
                ...Object.fromEntries(querystring.entries()), // Convert string to object
                ...params,
                ...matchParams
            },
            // Include match, location, history objects so we have
            // access to extra React Router functionality if needed.
            match,
            location,
            history,
            origin
        };
    }, [params, match, location, history]);
}

/**
 * See which prop changes are causing a component to re-render (troubleshooting hook)
 * @param {string} name name of component
 * @param {object} props collection of component properties
 * @returns {void}
 */
export function useWhyDidYouUpdate(name, props) { // reference: https://usehooks.com/useWhyDidYouUpdate/
    // Get a mutable ref object where we can store props ...
    // ... for comparison next time this hook runs.
    const previousProps = useRef();

    useEffect(() => {
        if (previousProps.current) {
            // Get all keys from previous and current props
            const allKeys = Object.keys({ ...previousProps.current, ...props });
            // Use this object to keep track of changed props
            const changesObj = {};
            // Iterate through keys
            allKeys.forEach(key => {
                // If previous is different from current
                if (previousProps.current[`${key}`] !== props[`${key}`]) {
                    // Add to changesObj
                    changesObj[`${key}`] = {
                        from: previousProps.current[`${key}`],
                        to: props[`${key}`]
                    };
                }
            });

            // If changesObj not empty then output to console
            if (Object.keys(changesObj).length) {
                console.log('[why-did-you-update]', name, changesObj);
            }
        }

        // Finally update previousProps with current props for next hook call
        previousProps.current = props;
    });
}

/**
 * Handle unmouted components asynchronisly
 * @param {Function} asyncFn asynchronis function
 * @param {Function} onSuccess function to run after complete
 * @returns {void}
 */
export function useAsync(asyncFn, onSuccess) { // https://stackoverflow.com/a/60907638
    useEffect(() => {
        let isMounted = true;
        asyncFn().then(data => {
            if (isMounted) onSuccess(data);
        });
        return () => { isMounted = false };
    }, [asyncFn, onSuccess]);
}

/**
 * https://stackoverflow.com/a/70803241
 * @param {ReactComponentElement} Component object to wrap
 * @returns {Function} wrapped component
 */
export function withRouter(Component) {
    /**
     * Load component router with given props
     * @param {object} props collection of props for react
     * @returns {ReactElement} react component
     */
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        return (
            <Component
                {...props}
                router={{ location, navigate, params }}
            />
        );
    }

    return ComponentWithRouterProp;
}
