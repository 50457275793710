
/**
 * Standard error handling function
 * @param {object} error error object with details
 */
export function onError(error) {
    if (error) {
        let message = error.toString();

        // Auth errors
        if (!(error instanceof Error) && error.message) {
            message = error.message;
        } else if (error.response && error.response.data) {
            message = `${error.response.status}: ${error.response.data.code} - ${error.response.data.message}`;
        }

        alert(message);
    }
}