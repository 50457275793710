import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { Form } from 'react-bootstrap';
import { LoaderButton } from './LoaderButton';
import { useFormFields } from '../assets/scripts/hooksLib';
import { onError } from '../assets/scripts/errorLib';
import '../assets/styles/resetPassword.scss';

/**
 * Password reset form
 * @returns {HTMLElement} html for password reset block
 */
export default function ResetPassword() {
    const [fields, handleFieldChange] = useFormFields({
        code: '',
        email: '',
        password: '',
        confirmPassword: ''
    });
    const [codeSent, setCodeSent] = useState(false);
    const [confirmed, setConfirmed] = useState(false);
    const [isConfirming, setIsConfirming] = useState(false);
    const [isSendingCode, setIsSendingCode] = useState(false);

    /**
     * Returns true if email address is complete
     * @returns {boolean} true or false
     */
    const validateCodeForm = () => {
        return fields.email.length > 0;
    };

    /**
     * Returns true if the code and password are complete
     * @returns {boolean} true or false
     */
    const validateResetForm = () => {
        return (
            fields.code.length > 0 &&
            fields.password.length > 0 &&
            fields.password === fields.confirmPassword
        );
    };

    /**
     * Sends a unique code to reset the user's password
     * @param {MouseEvent} event mouse click
     * @returns {void}
     */
    const handleSendCodeClick = async (event) => {
        event.preventDefault();

        setIsSendingCode(true);

        try {
            await Auth.forgotPassword(fields.email);
            setCodeSent(true);
        } catch (error) {
            onError(error);
            setIsSendingCode(false);
        }
    };

    /**
     * Confirms the unique code to complete the password reset
     * @param {MouseEvent} event mouse click
     * @returns {void}
     */
    const handleConfirmClick = async (event) => {
        event.preventDefault();

        setIsConfirming(true);

        try {
            await Auth.forgotPasswordSubmit(
                fields.email,
                fields.code,
                fields.password
            );
            setConfirmed(true);
        } catch (error) {
            onError(error);
            setIsConfirming(false);
        }
    };

    /**
     * Builds the request confirmation code block
     * @returns {HTMLElement} html
     */
    const renderRequestCodeForm = () => {
        return (
            <form onSubmit={handleSendCodeClick}>
                <Form.Group controlId='email'>
                    <Form.Control
                        autoFocus
                        type='email'
                        value={fields.email}
                        placeholder='Email'
                        onChange={handleFieldChange}
                    />
                </Form.Group>
                <LoaderButton
                    block='true'
                    type='submit'
                    isLoading={isSendingCode}
                    disabled={!validateCodeForm()}
                >
                    Send Confirmation
                </LoaderButton>
            </form>
        );
    };

    /**
     * Builds the confirmation input and password reset form
     * @returns {HTMLElement} html
     */
    const renderConfirmationForm = () => {
        return (
            <form onSubmit={handleConfirmClick}>
                <Form.Group controlId='code'>
                    <Form.Label>Confirmation Code</Form.Label>
                    <Form.Control
                        autoFocus
                        type='tel'
                        value={fields.code}
                        onChange={handleFieldChange}
                    />
                    <small className='form-text text-muted'>
                        Please check your email ({fields.email}) for the confirmation code.
                    </small>
                </Form.Group>
                <hr />
                <Form.Group controlId='password'>
                    <Form.Label>New Password</Form.Label>
                    <Form.Control
                        type='password'
                        value={fields.password}
                        onChange={handleFieldChange}
                    />
                </Form.Group>
                <Form.Group controlId='confirmPassword'>
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control
                        type='password'
                        value={fields.confirmPassword}
                        onChange={handleFieldChange}
                    />
                </Form.Group>
                <LoaderButton
                    block='true'
                    type='submit'
                    isLoading={isConfirming}
                    disabled={!validateResetForm()}
                >
                    Confirm
                </LoaderButton>
            </form>
        );
    };

    /**
     * Builds the password reset message block
     * @returns {HTMLElement} html
     */
    const renderSuccessMessage = () => {
        return (
            <div className='success'>
                <i className='fa-solid fa-check-circle' />
                <p>Your password has been reset.</p>
                <p>
                    <a href='/login.html' rel='nofollow'>
                        Click here to login with your new credentials.
                    </a>
                </p>
            </div>
        );
    };

    return (
        <div className='ResetPassword'>
            {!codeSent
                ? renderRequestCodeForm()
                : !confirmed
                    ? renderConfirmationForm()
                    : renderSuccessMessage()}
        </div>
    );
}
